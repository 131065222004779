<template>
  <div class="panel">
    <div>
      <info-table/>
    </div>
  </div>
</template>

<script>
import infoTable from './info-table'
export default {
  components: {
    infoTable
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 980px;
  min-height: 100%;
  padding: 20px 40px 30px 20px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  // width: 100%;
  // min-width: 1030px;
  // min-height: 100%;
  // padding: 0 30px;
  // background: #ffffff;
  // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  // border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
